import Ajax from "./Ajax";
import { Emit } from "./Notify";

const notify = (message, status, time) => Emit(message, status, time);

const Api = (url, method, query, data, cb) => Ajax(
    method,
    url,
    Object.assign(
        {
            "Accept": "application/json"
        },
        ["PUT", "POST"].includes(method) && {
            "Content-Type": "application/json"
        }
    ),
    query,
    ["PUT", "POST"].includes(method) ? JSON.stringify(data) : undefined,
    (status, contentType, text) => {
        if (status === 200 && contentType.split(";").map(e => e.trim()).includes("application/json")) {
            const data = JSON.parse(text);
            if (data.message) notify(...data.message);
            if (Array.isArray(data.navigate) && typeof(data.navigate[0]) === "string" && (data.navigate[1] === undefined || typeof(data.navigate[1]) === "number")) setTimeout(() => window.location.href = data.navigate[0], data.navigate[1]);
            if (typeof(data.navigate) === "string") window.location.href = data.navigate;
            cb(data);
        }
    }
);

export default Api;