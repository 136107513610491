import React from "react";
import Endpoints from "./Endpoints";
import Notify from "../../shared/Notify";
import CoreTools from "../../shared/CoreTools";
import InputWithLabel from "../../shared/InputWithLabel";

const TopBanner = () => <div className="banner-top">
    <img src="/shared/logos/cammservices.png" className="logo" alt="CammService Logo" />
    <span className="title">Gate Minder</span>
</div>;

const BottomBanner = () => <div className="banner-bottom">
    <span>Copyright © {new Date().getFullYear()} CAMM Services. All Rights Reserved.</span>
</div>;

const login = values => Endpoints.login(values, data => {
    if (data.success) window.location.reload();
});

const LoginForm = () => <div className="panel center-screen shadow p-3" style={{width: "300px"}}>
    <h1>Login</h1>
    <Notify.LastMessage />
    <form onSubmit={CoreTools.fromForm(login)}>
        <InputWithLabel label="E-mail Address" name="email" className="mt-2" />
        <InputWithLabel type="password" label="Password" name="password" className="mt-2" />
        <div className="text-end mt-2">
            <button className="btn btn-sm btn-120 bg-color-blue">Login</button>
        </div>
    </form>
</div>;

const App = () => {
    return <div>
        <TopBanner />
        <BottomBanner />
        <LoginForm />
    </div>;
};

export default App;