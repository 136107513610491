import CoreTools from "./CoreTools";

const limits = e => CoreTools.switch([e > 255, 255], [e < 0, 0], [true, e]);
const Colors = colors => {
    const palette = Object.assign(
        {
            primary: [50, 50, 180], // blue
            secondary: [100, 100, 100], // grey
            success: [50, 205, 50], // green
            danger: [200, 50, 50], // red
            warning: [205, 205, 50], // yellow
            info: [205, 50, 205], // purple
            light: [205, 205, 205],
            dark: [50, 50, 50]
        },
        colors
    );

    CoreTools.setTheme(Object.keys(palette).reduce((p, key) => {
        p[`--color-${key}`] = `rgb(${palette[key].join()})`;
        p[`--color-${key}-fade`] = `rgb(${palette[key].map(e => limits(e + 127)).join()})`;
        p[`--color-${key}-light`] = `rgb(${palette[key].map(e => limits(e + 50)).join()})`;
        p[`--color-${key}-shade`] = `rgb(${palette[key].map(e => limits(e - 30)).join()})`;
        p[`--color-${key}-dark`] = `rgb(${palette[key].map(e => limits(e - 50)).join()})`;
        return p;
    }, {}));
};

export default Colors;