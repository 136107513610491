import CoreTools from "./CoreTools";

const Ajax = (method, url, headers, query, data, cb) => {
    const xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = () => xhttp.readyState === 4 && cb(xhttp.status, xhttp.getResponseHeader("Content-Type"), xhttp.responseText);
    xhttp.open(method, CoreTools.isObject(query) ? [url, Object.keys(query).reduce((p, key) => {
        p.push([encodeURIComponent(key), encodeURIComponent(query[key])].join("="));
        return p;
    }, []).join("&")].join("?") : url, true);
    Object.keys(headers || {}).forEach(key => xhttp.setRequestHeader(key, headers[key]));
    xhttp.send(["POST", "PUT"].includes(method) ? data : undefined);
};

export default Ajax;