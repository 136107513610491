import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.jsx';
import Colors from "../../shared/Colors";

Colors();

Object.assign(document.body.style, {
  "background": `url('/shared/backgrounds/secure.jpeg') no-repeat center center fixed`,
  "background-size": "cover",
  "-webkit-background-size": "cover",
  "-moz-background-size": "cover",
  "-o-background-size": "cover"
});

const appDiv = document.createElement("div");
document.body.appendChild(appDiv);
ReactDOM.createRoot(appDiv).render(<App />);
